import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/tmp/node_modules/@nib/gatsby-theme-mesh-docs/src/templates/Content.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Mesh's design tokens are authored in the `}<a parentName="p" {...{
        "href": "https://github.com/nib-group/design-tokens"
      }}><inlineCode parentName="a">{`@nib-group/design-tokens`}</inlineCode></a>{` repo using a tool called `}<a parentName="p" {...{
        "href": "https://amzn.github.io/style-dictionary/"
      }}>{`Style Dictionary`}</a>{`. Style Dictionary allows us to have a single source of truth for tokens, and their values, defined in JSON. These token definitions are then used to generate many different formats to support different platforms and tech stacks. `}</p>
    <p>{`In react, our preferred format for design tokens is CSS Custom Properties.`}</p>
    <h2 {...{
      "id": "using-tokens",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-tokens",
        "aria-label": "using tokens permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Using tokens`}</h2>
    <p>{`The design tokens are available directly via the `}<inlineCode parentName="p">{`@nib-group/design-tokens`}</inlineCode>{` npm package. However, within React applications it is easier to access tokens via the `}<inlineCode parentName="p">{`@nib-components/theme`}</inlineCode>{` package and its `}<inlineCode parentName="p">{`token`}</inlineCode>{` function, for custom styling worker, or via provided props on mesh components.`}</p>
    <p>{`Many mesh components have been updated to consume design tokens for properties such as color, spacing and border radii. By updating to the latest version of `}<inlineCode parentName="p">{`@nib-components/theme`}</inlineCode>{` you can take advantage of these changes with minimal effort.`}</p>
    <h3 {...{
      "id": "the-box-component",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#the-box-component",
        "aria-label": "the box component permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`The Box component`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`Box`}</inlineCode>{` component has been updated to support tokenised values for `}<inlineCode parentName="p">{`background`}</inlineCode>{`, `}<inlineCode parentName="p">{`color`}</inlineCode>{` and `}<inlineCode parentName="p">{`borderColor`}</inlineCode>{`. Padding, margin and border radius values also now pull directly from tokens.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Box background="default" color="prominent" padding={4} borderRadius="small">
  <Copy>All powered by tokens!</Copy>
</Box>
`}</code></pre>
    <p>{`The old, non-tokenised values are still supported but should be considered deprecated and will be removed in a future release.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Box background="brandBase" color="nearWhite">
  <Copy>Still works for compatibility reasons</Copy>
</Box>
`}</code></pre>
    <p>{`Other components that are built on top of Box, or use the same prop-mapping logic to allow you to pick a foreground or background value also now have token support. These include:`}</p>
    <ul>
      <li parentName="ul">{`Section`}</li>
      <li parentName="ul">{`Heading`}</li>
      <li parentName="ul">{`Copy`}</li>
    </ul>
    <h3 {...{
      "id": "token-function",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#token-function",
        "aria-label": "token function permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Token function`}</h3>
    <p>{`When creating any custom component, the `}<inlineCode parentName="p">{`token`}</inlineCode>{` function is the recommended way to access tokens. It should be used within your css declarations for a styled-component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import styled, {css} from 'styled-components';
import {token, colorDark} from '@nib-components/theme';

export const Wrapper = styled.div\`
  background-color: \${token('theme.color.bg', '#f2f2f2')}; // string fallback
  color: \${token('theme.color.fg', colorDark)}; // selector fallback
  padding: \${token('common.dimension.spacing.6')};
\`;
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`token`}</inlineCode>{` function is typed to enable autocomplete and typescript errors when invalid token paths are provided.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`token`}</inlineCode>{` function has a second parameter to provide a fallback value if the token cannot be found for whatever reason. During the initial migration to tokens it is recommended that fallbacks be provided for all token references.`}</p>
    <p>{`See the `}<a parentName="p" {...{
        "href": "/components/theme/#token"
      }}>{`token function docs`}</a>{` for more information.`}</p>
    <h3 {...{
      "id": "modeprovider",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#modeprovider",
        "aria-label": "modeprovider permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`ModeProvider`}</h3>
    <p>{`The tokens release brings about a new concept of "modes". Like brands `}<em parentName="p">{`(themes)`}</em>{`, modes are a way to switch between different sets of tokens. For example, a "feature" mode might have a different color palette to the "default" mode. The `}<inlineCode parentName="p">{`ModeProvider`}</inlineCode>{` component is used to set the mode for a section of your app.`}</p>
    <p>{`The mesh `}<inlineCode parentName="p">{`ThemeProvider`}</inlineCode>{` component has been updated to have a nested `}<inlineCode parentName="p">{`ModeProvider`}</inlineCode>{` with a mode value of `}<inlineCode parentName="p">{`"default"`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import ThemeProvider, { ModeProvider, nib } from '@nib-components/theme';

const App = () => (
  <ThemeProvider theme={nib}>
    <main>
      <Columns space={4} collapseBelow="md">
        <Column width="2/3">
          Main content here
        </Column>
        <Column width="1/3">
          <ModeProvider mode="feature">
            <Box background="default" color="prominent">
              <Copy>This box and text is in the feature mode.</Copy>
            </Box>
          </ModeProvider>
        </Column>
      </Columns>
    </main>
  </ThemeProvider>
);
`}</code></pre>
    <p><inlineCode parentName="p">{`ModeProvider`}</inlineCode>{` can be nested within other `}<inlineCode parentName="p">{`ModeProvider`}</inlineCode>{`'s to allow for different modes to be set at different levels of your component tree. The mode prop can be set to a string to apply for all brands, or an object to apply for specific brands. If you have `}<em parentName="p">{`many`}</em>{` brands to support, we have a special key of `}<inlineCode parentName="p">{`rest`}</inlineCode>{` which will apply to any brand not set in the dictionary:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { ModeProvider } from '@nib-components/theme';

const App = () => (
  <ModeProvider mode={{nib: 'feature', gu: 'alt', rest: 'default'}}>
    <Box background="default" color="prominent">
      <Copy>This box and text is in the feature mode.</Copy>
    </Box>
  </ModeProvider>
);
`}</code></pre>
    <h3 {...{
      "id": "theme-selectors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#theme-selectors",
        "aria-label": "theme selectors permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Theme selectors`}</h3>
    <p>{`Up until now the correct approach to styling custom components was to use the selectors exported from our theme package.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import {colorBrandDark, colorWhite} from '@nib-components/theme';

const Wrapper = styled.div\`
  background-color: \${colorBrandDark};
  color: \${colorWhite};
\`;
`}</code></pre>
    <p>{`With the introduction of tokens, this will change. Color selectors have `}<strong parentName="p">{`not`}</strong>{` been updated to reference tokens. This is because these color selectors are not semantic and therefore would be difficult to point to a token as a sensible default value. For example, it may seem reasonable to point the `}<inlineCode parentName="p">{`colorBrandBase`}</inlineCode>{` selector at `}<inlineCode parentName="p">{`theme.color.fg.brand`}</inlineCode>{` but the color selector is not solely used for text color. It is also used for backgrounds, borders and component-level styling. This highlights one of the primary reasons for undergoing the tokens work. There needs to be a level of safety in knowing that foreground color options will have sufficient contrast on background colors.`}</p>
    <p>{`Other theme selectors that have a 1:1 mapping with tokens have been updated. These include:`}</p>
    <ul>
      <li parentName="ul">{`spacing scale`}</li>
      <li parentName="ul">{`border radii`}</li>
    </ul>
    <p>{`Spacing utilities like `}<inlineCode parentName="p">{`p(4)`}</inlineCode>{` and `}<inlineCode parentName="p">{`m(4)`}</inlineCode>{` now pull from the spacing scale tokens and do not need to be modified in your code base:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import {p, mb} from '@nib-components/theme';

const Wrapper = styled.div\`
  \${p(4)};
  \${mt(2)};
  \${mb({xs: 4, md: 6})};
\`;
`}</code></pre>
    <h2 {...{
      "id": "resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#resources",
        "aria-label": "resources permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/mode-provider/"
        }}>{`ModeProvider docs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/theme/"
        }}>{`Theme docs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/nib-group/design-tokens"
        }}>{`Design Tokens repo`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/nib-group/design-system/blob/master/TOKENS_MIGRATION.md"
        }}>{`Release notes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/nib-group/design-system/blob/master/TOKENS_RELEASE_NOTE.md"
        }}>{`Migration Guide`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      